<template>
    <v-row>
      <v-col cols="3" v-for="item in gallery" :key="item">
          
        <v-card class=" shadow p-2" outlined color="">
          <v-card-title class="py-0 px-0 mb-1 p-5">
              <v-btn icon x-small class="red" @click="deleteImage(item)" v-if="!item.startsWith('blob:')">
                  <v-icon class="white--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
          <v-img :src="item" :max-width="400" class="rounded-lg">
            
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </template>
  <script>
  export default {
    props: ["images"],
    data(){
      return {
        render: 0,
        gallery: []
      }
    },
    methods: {
      deleteImage(item){
        this.$emit("deleteImage", item);
      },
      forceRender(images){
        this.render++;
        this.gallery = images;
      }
    },
    watch: {
      images(value){
        this.gallery = value;
      }
    },
    mounted(){
      this.gallery = this.images;
    }
  };
  </script>