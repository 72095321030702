<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Modifier une API Ecommerce
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <v-col cols="12">
            <v-select
              disabled
              dense
              clearable
              :items="services"
              v-model="form.service_id"
              :item-text="'name'"
              :item-value="'id'"
              label="Service..."
              hide-details
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                    :src="getImageLink(slotProps.item.image)"
                    max-height="25"
                    max-width="25"
                    width="25"
                    height="25"
                    class="p-2"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
              :loading="$store.getters.ecommerceModelsLoading"
              dense
              clearable
              :items="models"
              v-model="form.model_id"
              :item-text="'name'"
              :item-value="'id'"
              label="Modèle..."
              hide-details
              @change="modelChanged"
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                    :src="getImageLink(slotProps.item.image)"
                    max-height="25"
                    max-width="25"
                    width="25"
                    height="25"
                    class="p-2"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="Url *"
              @input="
                formErrors && formErrors.url ? (formErrors.url = '') : false
              "
              :error-messages="
                formErrors && formErrors.url ? formErrors.url[0] : ''
              "
              v-model="form.url"
          /></v-col>

          <v-col cols="12">
            <div class="d-flex align-center justify-space-between">
              <span class="font-weight-bold"> Paramètres </span>
              <!-- <v-btn
                icon
                depressed
                class="primary white--text"
                @click="addParameter"
              >
                <v-icon>mdi mdi-plus</v-icon>
              </v-btn> -->
            </div>

            <div
              class="d-flex align-center"
              v-for="(param, index) in newParams"
              :key="'added-'+index"
            >
              <v-text-field
                v-model="newParams[index].key"
                label="Nom "
                class="mr-1"
              ></v-text-field>
              <v-text-field
                v-model="newParams[index].value"
                label="Valeur"
                class="ml-1"
              ></v-text-field>
            </div>


            <div
              class="d-flex align-center"
              v-for="(param, index) in form.params"
              :key="index"
            >
              <span>{{ param.key }} : </span>
              <v-text-field v-model="form.params[index].value"></v-text-field>
            </div>


            <v-btn
              color="primary"
              class="rounded-lg text-none mt-3"
              :loading="btnLoading"
              large
              depressed
              @click="send"
            >
              <v-icon left>mdi-send-outline</v-icon>
              Savegarder
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>
      
      <script>
import { HTTP } from "@/http-common";



export default {
  props: ["service"],
  components: {},
  data() {
    return {
      btnLoading: false,

      formErrors: {},
      form: {
        url: null,
        service_id: null,
        model_id: null,
        params: [],
      },
      newParams: [],
      dialog: false,
      api: null,
    };
  },

  computed: {
    services() {
      return this.$store.getters.getServices.data;
    },

    models() {
      return this.$store.getters.getEcommerceModels;
    },
  },
  methods: {
    modelChanged() {
      var model = this.models.find((x) => x.id === this.form.model_id);
      var params = model.params.split(",");

      this.form.params = [];

      for (let index = 0; index < params.length; index++) {
        this.form.params.push({
          key: params[index],
          value: "",
        });
      }
    },
    addParameter() {
      this.newParams.push({
        key: "",
        value: "",
      });
    },
    open(item) {

      if(this.$refs && this.$refs.fileImage){
        this.$refs.fileImage.reset();
      }
      
      this.api = item;

      this.form.model_id = item.model_id;
      this.form.url = item.url;
      this.form.service_id = item.service_id;
      this.form.params = [];

      console.log(item);
        
      if(item.params){
        item.params.forEach(element => {
        console.log(element.key);
       

        this.form.params.push({
            key: element.key,
            value: element.value
        });
      })
      }else{
        item.params
      }

      

      this.dialog = true;
    },
    close() {
      //this.$emit("update:dialog", false);
      this.dialog = false;
    },
    send() {
      //console.log(this.form);
      this.btnLoading = true;

      var paramsForm = [];
        
      this.form.params.forEach((element) => {
        paramsForm.push(element);
      });
      this.newParams.forEach((element) => {
        paramsForm.push(element);
      });

      HTTP.post(
        "/v1/ecommerce/apis/" + this.api.id,
        {
          url: this.form.url,
          service_id: this.form.service_id,
          model_id: this.form.model_id,
          params: paramsForm,
          _method: "PUT",
        },
        { params: {} }
      )
        .then(() => {
          this.$emit("refreshListApi");
          this.$successMessage = "Enregistrement avec succès";
          this.btnLoading = false;
          this.close();
          this.form = {};
          this.$refs.fileImage.reset();

          this.dialog = false;
        })
        .catch((err) => {
          this.btnLoading = false;
          this.formErrors = err.response.data.errors;
        });
    },

    
  },
};
</script>
      
      