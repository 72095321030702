<template>
    <div>
      <v-dialog v-model="openDialog" max-width="600" persistent>
        <v-card>
          <v-card-title>
            Changer l'etat d'un service
            <v-spacer />
            <v-btn icon class="v-btn--active" color="primary" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-6"  style="overflow-y: scroll; max-height: 450px;">
  
            <form @submit.prevent="send">
              
                <v-tabs v-model="language_tab" align-with-title>
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab>
                    Francais
                </v-tab>
                <v-tab>
                    Arabe
                </v-tab>
                </v-tabs>

                <v-tabs-items v-model="language_tab">
                    <v-tab-item>
                        <v-card flat>
                        <v-card-text>
                            <v-textarea
                                name="input-7-1"
                                label="Commentaire"
                                v-model="form.message"
                                hint="Decrivez la raison de ce changement"
                                ></v-textarea>
                        </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item style="direction: rtl">
                        <v-card flat>
                        <v-card-text class="arabic">
                            <v-textarea
                                style="direction: rtl; right: 0;"
                                name="input-7-1"
                                label="Commentaire arabe"
                                v-model="form.message_ar"
                                hint="Decrivez la raison de ce changement en arabe"
                                ></v-textarea>
                        </v-card-text>
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>

              

              <v-btn
                color="primary"
                class="rounded-lg text-none mt-3"
                :loading="btnLoading"
                large
                depressed
                @click="send"
              >
                <v-icon left>mdi-send-outline</v-icon>
                Savegarder
              </v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { HTTP } from "@/http-common";
  
  export default {
    data() {
      return {
        btnLoading: false,
  
        errors: null,
        service_id: null,
        form: {
          state_id: null,
          message: '',
          message_ar: ''
        },
        openDialog: false,
        language_tab: null
      };
    },
  
    computed: {
    
    },
    methods: {

    open(id, state_id){
        
        this.openDialog = true;
        this.service_id = id;
        this.form.state_id = state_id;
    },
      close() {
        //this.$emit("update:openDialog", false);
        this.openDialog = false
      },
      send() {
        this.btnLoading = true;
  
        HTTP.post("/service/update_state/"+this.service_id, this.form, { params: {} })
          .then(() => {
              this.$successMessage = "Ce Service a été mis à jour avec succès";
              this.btnLoading = false;
              this.close();
              this.form = {};
              this.openDialog = false;
              this.$emit('refresh')

          })
          .catch((err) => {
            this.btnLoading = false;
            this.errors = err.response.data.errors;
          });
      },
  
      uploadFile(file) {
        this.uploadForm.image = file;
      },
      
    },
    created() {
      this.$store.dispatch("fetchServiceTypes");
      this.$store.dispatch("fetchServiceStates");
    },
  };
  </script>
  
<style scoped>
.arabic .v-text-field__slot .v-label{
    text-align: right !important;
    direction: rtl;
    left: inherit;
    right: 0 !important;
}
</style>
  