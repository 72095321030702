<template>
  <div>
    <v-dialog v-model="showAddServiceDialog" max-width="800" persistent>
      <v-card>
        <v-card-title>
          Ajouter un services
          <v-spacer/>
          <v-btn class="v-btn--active" color="primary" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-6 px-10" style="overflow-y: scroll; max-height: 450px">
          <form enctype="multipart/form-data" @submit.prevent="send">
            <v-tabs v-model="language_tab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab> Francais</v-tab>
              <v-tab> Arabe</v-tab>
            </v-tabs>

            <v-tabs-items v-model="language_tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-text-field
                        v-model="form.name"
                        :error-messages="
                        formErrors && formErrors.name ? formErrors.name[0] : ''
                      "
                        label="Désignation *"
                        @input="
                        formErrors && formErrors.name
                          ? (formErrors.name = '')
                          : false
                      "
                    />

                    <v-textarea
                        v-model="form.short_description"
                        :error-messages="formErrors.short_description"
                        hint="Petite description"
                        label="Petite description"
                        name="input-7-1"
                        @input="
                        formErrors && formErrors.short_description
                          ? (formErrors.short_description = '')
                          : false
                      "
                    ></v-textarea>

                    <p>Description</p>
                    <!-- Rich text -->
                    <ckeditor v-model="form.description"/>

                    <p class="mt-3">Utilisation</p>
                    <!-- Rich text -->
                    <ckeditor v-model="form.usage"/>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item style="direction: rtl">
                <v-card flat>
                  <v-card-text class="arabic">
                    <v-text-field
                        v-model="form.name_ar"
                        :error-messages="
                        formErrors && formErrors.name_ar
                          ? formErrors.name_ar[0]
                          : ''
                      "
                        label="Désignation en arabe *"
                        @input="
                        formErrors && formErrors.name_ar
                          ? (formErrors.name_ar = '')
                          : false
                      "
                    />

                    <v-textarea
                        v-model="form.short_description_ar"
                        :error-messages="formErrors.short_description_ar"
                        hint="Petite description (Arabe)"
                        label="Petite description"
                        style="direction: rtl; right: 0"
                        @input="
                        formErrors && formErrors.short_description_ar
                          ? (formErrors.short_description_ar = '')
                          : false
                      "
                    ></v-textarea>

                    <p style="direction: ltr; right: 0">Description en arabe</p>
                    <!-- Rich text -->
                    <ckeditor v-model="form.description_ar"/>

                    <p class="mt-3" style="direction: ltr; right: 0">
                      Utilsation en arabe
                    </p>
                    <!-- Rich text -->
                    <ckeditor v-model="form.usage_ar"/>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-text-field
                v-model="form.url"
                :error-messages="
                formErrors && formErrors.url ? formErrors.url[0] : ''
              "
                label="Url service (Site web, e-commerce ..) *"
                prepend-icon="mdi-link"
                @input="
                formErrors && formErrors.url ? (formErrors.url = '') : false
              "
            />

            <div class="d-flex mt-4">
              <v-select
                  v-model="form.service_type_id"
                  :error-messages="formErrors.service_type_id"
                  :items="types"
                  dense
                  filled
                  item-text="name"
                  item-value="id"
                  label="Type du service "
                  style="width: 20px"
                  @change="TypeChanged($event)"
                  @input="
                  formErrors && formErrors.service_type_id
                    ? (formErrors.service_type_id = '')
                    : false
                "
              >
              </v-select>

              <v-btn
                  class="mx-1 mt-1"
                  color="primary"
                  dark
                  elevation="0"
                  fab
                  small
                  @click="addType"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </div>

            <v-select
                v-show="typeName == 'Carte'"
                v-model="form.card_type"
                :error-messages="formErrors.card_type"
                :items="cardTypes"
                dense
                filled
                item-text="name"
                item-value="id"
                label="Type du Carte "
                @input="
                formErrors && formErrors.card_type
                  ? (formErrors.card_type = '')
                  : false
              "
            >
            </v-select>

            <v-autocomplete
                v-model="form.categories"
                :error-messages="formErrors.categories"
                :items="categories"
                chips
                class="mb-4"
                clearable
                deletable-chips
                item-text="name"
                item-value="id"
                label="Catégories"
                multiple
                small-chips
                @input="
                formErrors && formErrors.categories
                  ? (formErrors.categories = '')
                  : false
              "
            >
              <template v-slot:item="slotProps">
                <div
                    class="d-flex align-center justify-start"
                    style="width: 50px"
                >
                  <v-checkbox
                      :value="in_array(form.categories, slotProps.item.id)"
                  >
                  </v-checkbox>
                  <img
                      :src="getImageLink(slotProps.item.image)"
                      height="25"
                      max-height="25"
                      max-width="25"
                      style="margin-right: 5px"
                      width="25"
                  />
                </div>
                <span class="ml-3">{{ slotProps.item.name }}</span>
              </template>
            </v-autocomplete>

            <v-checkbox
                v-if="typeName == 'Refill'"
                v-model="form.refill_with_voucher"
                label="Refill avec des vouchers ?"
            />

            <v-select
                v-show="
                typeName == 'Voucher' ||
                (typeName == 'Refill' && form.refill_with_voucher)
              "
                v-model="form.supplier_id"
                :error-messages="formErrors.supplier_id"
                :item-text="'name'"
                :item-value="'id'"
                :items="suppliers"
                chips
                clearable
                deletable-chips
                dense
                hide-details
                label="Supplier..."
                prepend-icon="mdi-filter-outline"
                small-chips
                @input="
                formErrors && formErrors.supplier_id
                  ? (formErrors.supplier_id = '')
                  : false
              "
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                      :src="getImageLink(slotProps.item.image)"
                      class="p-2"
                      height="25"
                      max-height="25"
                      max-width="25"
                      width="25"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-select>

            <div
                v-show="
                typeName == 'Voucher' &&
                in_array(['admin'], $store.getters.getUser.roleName)
              "
            >
              <v-checkbox
                  v-model="form.with_build_vouchers"
                  label="Avec Genaration dynamique"
              ></v-checkbox>
            </div>

            <div v-show="typeName == 'Voucher' && form.with_build_vouchers">
              <v-checkbox
                  v-model="form.with_dynamic_api"
                  label="Avec API?"
              ></v-checkbox>
            </div>

            <div>
              <v-checkbox
                  v-model="form.with_order"
                  label="Avec Commande"
              ></v-checkbox>
            </div>

            <v-checkbox v-model="form.visible" label="Visible ?"></v-checkbox>

            <v-checkbox
                v-model="form.top_service"
                label="Top Service ?"
            />

            <v-checkbox
                v-model="form.physical_store"
                label="Stockage physique ?"
            />

            <div class="d-flex mt-4">
              <v-select
                  v-model="form.service_state_id"
                  :error-messages="formErrors.service_state_id"
                  :items="states"
                  dense
                  filled
                  item-text="name"
                  item-value="id"
                  label="Etat du service "
                  style="width: 20px"
                  @input="
                  formErrors && formErrors.service_state_id
                    ? (formErrors.service_state_id = '')
                    : false
                "
              >
                <template #item="{ item }">
                  <span>
                    <v-chip
                        :color="item.color"
                        class="ma-2"
                        label
                        text-color="white"
                    >
                      {{ item.name }}
                    </v-chip>
                  </span>
                </template>

                <template #selection="{ item }">
                  <span>
                    <v-chip
                        :color="item.color"
                        class="ma-2"
                        label
                        text-color="white"
                    >
                      {{ item.name }}
                    </v-chip>
                  </span>
                </template>
              </v-select>
            </div>

            <div class="d-flex align-center">
              <v-file-input
                  ref="file"
                  :error-messages="formErrors.image"
                  accept="image/png, image/jpeg, image/jpg, image/svg, .svg"
                  chips
                  label="Image *"
                  prepend-icon="mdi-camera"
                  show-size
                  suffix="Image"
                  @change="uploadFile"
                  @focus="
                  formErrors && formErrors.image
                    ? (formErrors.image = '')
                    : false
                "
              />
              <v-btn
                  v-if="form.image"
                  class="primary white--text ml-2"
                  depressed
                  icon
                  small
                  @click="previewImage(form.image)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>

            <v-row>
              <v-col cols="6">
                Background color
                <v-text-field
                    v-model="form.background_color"
                    v-mask="mask"
                    class="ma-0 pa-0"
                    hide-details
                    solo
                >
                  <template v-slot:append>
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        nudge-bottom="105"
                        nudge-left="16"
                        top
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on"/>
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="form.background_color"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                Coleur du Texte
                <v-text-field
                    v-model="form.text_color"
                    v-mask="mask"
                    class="ma-0 pa-0"
                    hide-details
                    solo
                >
                  <template v-slot:append>
                    <v-menu
                        v-model="menuTextColor"
                        :close-on-content-click="false"
                        nudge-bottom="105"
                        nudge-left="16"
                        top
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="swatchTextColor" v-on="on"/>
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker v-model="form.text_color" flat/>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-col cols="12">
              <div class="d-flex align-center">
                <v-file-input
                    ref="backgroundFile"
                    :error-messages="formErrors.background_image"
                    accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                    chips
                    label="Background Image *"
                    prepend-icon="mdi-camera"
                    show-size
                    suffix="Image"
                    @change="uploadBackgroundFile"
                    @focus="
                    formErrors && formErrors.background_image
                      ? (formErrors.background_image = '')
                      : false
                  "
                />
                <v-btn
                    v-if="form.background_image"
                    class="primary white--text ml-2"
                    depressed
                    icon
                    small
                    @click="previewImage(form.background_image)"
                >
                  <v-icon small>mdi mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12">
              <v-file-input
                  ref="gallery"
                  :error-messages="formErrors.gallery"
                  accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                  chips
                  label="Gellery *"
                  multiple
                  prepend-icon="mdi-camera"
                  show-size
                  suffix="Image"
                  @change="uploadGallery"
                  @focus="
                  formErrors && formErrors.gallery
                    ? (formErrors.gallery = '')
                    : false
                "
              />
              <PreviewImages ref="previewImages" :images="imagesToPreview"/>
            </v-col>

            <v-checkbox v-model="form.is_parent"
                        :hide-details="!!(form.is_parent)"
                        label="Service Parent"/>

            <v-autocomplete v-if="!form.is_parent"
                            v-model="form.service_parent_id"
                            :error-messages="formErrors.service_parent_id"
                            :items="services.data.filter(el=>el.is_parent === 1)"
                            filled
                            hide-details="auto"
                            item-text="name"
                            item-value="id"
                            label="Service parent *"
            ></v-autocomplete>

            <v-checkbox v-model="form.is_refundable"
                        hide-details
                        label="Remboursable ?"/>

            <v-text-field
                v-model="form.external_url"
                :error-messages="
                formErrors && formErrors.external_url ? formErrors.external_url[0] : ''
              "
                label="URL externe"
                @input="
                formErrors && formErrors.external_url ? (formErrors.external_url = '') : false
              "
            />

            <p>
              Contact & Réseaux Sociaux :
            </p>

            <v-simple-table class="table-border rounded-lg">
              <template v-slot:default>
                <thead>
                <tr>
                  <th>Nom</th>
                  <th>Valeur</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,i) in form.contact_details" :key="i">
                  <td>
                    <v-select dense
                              outlined
                              hide-details
                              :items='["facebook", "instagram", "youTube", "linkedIn", "x", "threads","link", "phone"]'
                              v-model="item.name"></v-select>
                  </td>
                  <td>
                    <v-text-field outlined dense hide-details v-model="item.value"></v-text-field>
                  </td>
                  <td>
                    <v-icon color="red" @click="form.contact_details.splice(i,1)">mdi-close</v-icon>
                  </td>
                </tr>
                <tr v-if="!form.contact_details.length">
                  <td colspan="3" class="text-center">
                    Aucun enregistrement !
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div class="text-center mt-3">
              <v-icon color="gifty" @click="form.contact_details.push({name : '',value : ''})">mdi-plus-circle</v-icon>
            </div>


            <div v-if="formErrors && formErrors.userIds" class="red--text">
              {{ formErrors.userIds[0] }}
            </div>

            <v-checkbox
                v-model="form.is_default_pos"
                hide-details
                label="Service affecté à tous les pos?"
            ></v-checkbox>

            <v-radio-group v-if="!form.is_default_pos"
                           v-model="form.users_assignment_type"
                           label="Affectation *" row>
              <v-radio label="Choisir" value="specify"/>
              <v-radio label="Exclure" value="exclude"/>
            </v-radio-group>

            <div class="d-flex justify-space-between">
              <div>
                <v-btn
                    v-if="!form.is_default_pos"
                    class="secondary rounded-lg mt-3"
                    depressed
                    large
                    @click="selectUsers"
                >
                  Affecter des Utilisateurs ({{ form.userIds.length }})
                </v-btn>
              </div>

              <v-btn :loading="btnLoading"
                     class="text-none mt-3"
                     color="primary"
                     depressed
                     @click="send"
              >
                <v-icon left>mdi-content-save</v-icon>
                Enregistrer
              </v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AddServiceTypeDialog ref="addTypeServiceDialog" @refresh="refreshTypes"/>
    <SelectUsers ref="selectUsers" @selected="setUsers($event)"/>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import AddServiceTypeDialog from "@/views/serviceTypes/dialog/AddServiceTypeDialog.vue";
import SelectUsers from "@/views/publicities/components/SelectUsers.vue";
import PreviewImages from "./PreviewImages.vue";
// import the component and the necessary extensions


export default {
  components: {AddServiceTypeDialog, SelectUsers, PreviewImages},
  props: ["showAddServiceDialog"],
  data() {
    return {
      btnLoading: false,
      formErrors: [],
      errors: null,
      typeName: null,
      form: {
        image: "",
        name_ar: "",
        name: "",
        service_type_id: "",
        service_state_id: "",
        with_order: false,
        is_parent: true,
        service_parent_id: "",
        message: "",
        message_ar: "",
        description: "",
        description_ar: "",
        icon: null,
        background_color: "#000000",
        text_color: "#000000",
        background_image: "",
        supplier_id: null,
        card_type: null,
        visible: true,
        //order: 0,
        is_default_pos: false,
        userIds: [],
        categories: [],
        with_build_vouchers: false,
        refill_with_voucher: false,
        with_dynamic_api: false,
        filter: "",
        gallery: "",
        usage: "",
        usage_ar: "",
        short_description: "",
        short_description_ar: "",
        top_service: false,
        physical_store: false,
        users_assignment_type: 'specify',

        contact_details: [],
        is_refundable: false,
        external_url: "",
      },
      language_tab: null,

      // color picker
      mask: "XXXXXXX",
      menu: false,
      menuTextColor: false,

      cardTypes: [
        {
          id: "Idoom",
          name: "Idoom",
        },
        {
          id: "4G",
          name: "4G",
        },
      ],
    };
  },

  computed: {
    types() {
      return this.$store.getters.getServiceTypes;
    },

    states() {
      return this.$store.getters.getServiceStates;
    },

    services() {
      return this.$store.getters.getServices;
    },

    suppliers() {
      return this.$store.getters.getSuppliers;
    },

    categories() {
      return this.$store.getters.getCategories.data;
    },
    background_color() {
      return this.form.background_color;
    },
    swatchStyle() {
      var color = this.background_color;
      var menu = this.menu;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    swatchTextColor() {
      return {
        backgroundColor: this.form.text_color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.form.menuTextColor ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    imagesToPreview() {
      return this.getImagesToPreview();
    },
  },
  methods: {
    selectUsers() {
      this.$refs.selectUsers.open();
    },
    setUsers(e) {
      this.form.userIds = e.users;
      this.form.filter = e.filter;

      console.log(e.filter);
    },
    TypeChanged() {
      var type = this.types.find(
          (x) => x.id === this.form.service_type_id
      ).name;
      this.typeName = type;
    },
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },
    addType() {
      this.$refs.addTypeServiceDialog.open();
    },
    close() {
      this.$emit("update:showAddServiceDialog", false);
    },
    send() {
      this.btnLoading = true;

      let formData = new FormData();

      if (this.form.visible) {
        this.form.visible = 1;
      } else {
        this.form.visible = 0;
      }
      if (this.form.with_order) {
        this.form.with_order = 1;
      } else {
        this.form.with_order = 0;
      }

      if (this.form.is_default_pos) {
        this.form.is_default_pos = 1;
      } else {
        this.form.is_default_pos = 0;
      }

      if (this.form.with_build_vouchers) {
        this.form.with_build_vouchers = 1;
      } else {
        this.form.with_build_vouchers = 0;
      }

      if (this.form.with_dynamic_api) {
        this.form.with_dynamic_api = 1;
      } else {
        this.form.with_dynamic_api = 0;
      }

      if (this.form.top_service) {
        this.form.top_service = 1;
      } else {
        this.form.top_service = 0;
      }

      if (this.form.refill_with_voucher) {
        this.form.refill_with_voucher = 1;
      } else {
        this.form.refill_with_voucher = 0;
      }

      if (this.form.physical_store) {
        this.form.physical_store = 1;
      } else {
        this.form.physical_store = 0;
      }

      if (this.form.is_parent) {
        this.form.is_parent = 1;
      } else {
        this.form.is_parent = 0;
      }

      if (this.form.is_refundable) {
        this.form.is_refundable = 1;
      } else {
        this.form.is_refundable = 0;
      }

      Object.keys(this.form).forEach((key) => {
        if (key === "gallery" && Array.isArray(this.form[key])) {
          this.form[key].forEach((element) => {
            formData.append(key + "[]", element);
          });
        } else if (key === "contact_details") {
          this.form[key].forEach((element, index) => {
            Object.keys(element).forEach((objKey) => {
              formData.append(`${key}[${index}][${objKey}]`, element[objKey]);
            })
          })
        } else {
          formData.append(key, this.form[key]);
        }
      });


      this.formErrors = {};
      HTTP.post("/service", formData)
          .then(() => {
            this.$successMessage = "Ce Service a été crée avec succès";
            this.btnLoading = false;
            this.close();

            this.$refs.file.reset();
            this.$refs.gallery.reset();
            this.$refs.backgroundFile.reset();
            this.showAddServiceDialog = false;
            window.location.reload();
          })
          .catch((err) => {
            this.btnLoading = false;
            if (err.response && err.response.data && err.response.data.errors) {
              this.formErrors = err.response.data.errors;
            } else {
              this.$errorMessage = "Une erreur s'est produite";
            }
          });
    },

    uploadFile(file) {
      this.form.image = file;
    },
    uploadGallery(files) {
      this.form.gallery = files;

      this.renderGalleryPreview();
    },
    uploadBackgroundFile(file) {
      this.form.background_image = file;
    },
    refreshTypes() {
      this.$store.dispatch("fetchServiceTypes");
    },
    renderGalleryPreview() {
      if (this.$refs.previewImages) {
        this.$refs.previewImages.forceRender(this.getImagesToPreview());
      }
    },

    getImagesToPreview() {
      var gallery = [];

      if (this.form.gallery && Array.isArray(this.form.gallery)) {
        for (let index = 0; index < this.form.gallery.length; index++) {
          gallery.push(URL.createObjectURL(this.form.gallery[index]));
        }
      }
      return gallery;
    },
  },
  created() {
    this.$store.dispatch("fetchServiceTypes");
    this.$store.dispatch("fetchServiceStates");
  },
};
</script>

<style scoped>
</style>
