<template>
    <div>
      <v-dialog v-model="dialog" max-width="800" persistent>
        <v-card class="text-center">
          <v-card-title>
              Les Apis Service {{ service? service.name : "" }}
              <v-spacer />
              <v-btn
                icon
                class="v-btn--active"
                color="primary"
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
          <v-card-text class="pa-6">
            
            <ListApis :service="service" ref="ListApis"/>
          </v-card-text>
        </v-card>
      </v-dialog>

      
    </div>
  </template>
  
  <script>
  import ListApis from './ListApis.vue';

  export default {
    components: {ListApis},
    data() {
      return {
        dialog: false,
        is_confirm: false,
        confirm_dialog: false,
        service: null
      }
    },
    methods: {
        open(item){
          this.service = item;
          this.dialog = true
          
        },
        close(){
          this.dialog = true
        }
    }, 
    mounted(){

    }
  }
  </script>