<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">
          <v-icon
            color="success"
            size="80"
            class="animate__animated animate__rotateIn"
          >
            mdi-information-outline
          </v-icon>
          <v-alert v-if="success" type="success"> Test avec succès</v-alert>
          <v-alert v-if="error" type="error"> une erreur s'est produite</v-alert>

          <h2 class="text--primary mt-3 mb-5">Tester une API</h2>

          <v-btn text class="rounded-lg mr-2 text-none" large @click="close()">
            <v-icon left>mdi-close</v-icon> Annuler
          </v-btn>
          <v-btn
            class="rounded-lg text-none"
            large
            color="primary"
            depressed
            :loading="loading"
            @click="test()"
          >
            <v-icon left>mdi-play</v-icon> Tester
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      dialog: false,
      loading: false,

      error: false,
      success: false,
      api: null,
    };
  },
  methods: {
    open(api) {
        this.error = false;
      this.success = false;
      this.api = api;
      this.dialog = true;
    },
    test() {
      this.loading = true;
      this.error = false;
      this.success = false;

      HTTP.post("/v1/ecommerce/apis/check/" + this.api.id)
        .then((res) => {
          this.loading = false;
          this.error = false;
          this.success = true;
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.error = true;
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
