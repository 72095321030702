<template>
  <div>
    <v-card
      class="rounded-lg shadow"
      :loading="isLoading"
      :disabled="isLoading"
      elevation="0"
    >
      <v-card-text class="p-3 pt-0">
        <v-skeleton-loader v-if="isLoading" type="table" />

        <div v-else>
          <div class="d-flex justify-space-between mb-2">
            <div></div>
            <div class="d-flex justify-space-between">
              <v-text-field
                rounded
                filled
                dense
                style="width: 300px"
                append-icon="mdi-magnify"
                single-line
                hide-details
                clearable
                placeholder="Rechercher..."
                v-on:keyup.enter="getItems"
              />

              <v-btn
                rounded
                color="primary"
                class="ml-1"
                elevation="0"
                @click="addApi"
                >Ajouter</v-btn
              >
            </div>
          </div>

          <v-divider />

          <v-simple-table elevation="0" style="overflow: auto">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Test</th>
                  <th>ID</th>
                  <th>Url</th>
                  <th>Modèle</th>
                  <th>Parametres</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in apis" :key="item.id">
                  <td>
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="testApi(item)"
                        >
                          <v-icon>mdi-play</v-icon>
                        </v-btn>
                      </template>
                      <span>Lancer un test</span>
                    </v-tooltip>
                  </td>
                  <td>
                    {{ item.id }}
                  </td>
                  <td class="text-no-wrap">
                    {{ item.url }}
                  </td>
                  <td class="text-no-wrap">
                    {{ item.model ? item.model.name : "" }}
                  </td>
                  <td class="text-no-wrap">
                    <ul>
                      <li
                        v-for="(p, index) in item.params"
                        :key="'p' + index"
                        class="text-left"
                      >
                        <b>{{ p.key }}</b
                        >: {{ p.value }}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <v-menu transition="slide-x-transition" bottom right>
                      <v-list dense>
                        <v-list-item @click="testApi(item)">
                          <v-list-item-icon>
                            <v-icon color="black">mdi-play</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Tester</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="updateApi(item)">
                          <v-list-item-icon>
                            <v-icon color="black">mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Modifier</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteApi(item)">
                          <v-list-item-icon>
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Supprimer</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
    <AddApiDialog ref="addApiDialog" @refreshListApi="getItems()" />
    <UpdateApiDialog ref="updateApiDialog" @refreshListApi="getItems()" />
    <ConfirmDelete @confirmed="removeApi" ref="confirmDelete" />
    <TestApi ref="testApi" />
  </div>
</template>

<script>
import { HTTP } from "@/http-common";

import AddApiDialog from "./components/AddApiDialog.vue";
import UpdateApiDialog from "./components/UpdateApiDialog.vue";
import ConfirmDelete from "./components/ConfirmDelete.vue";

import TestApi from "./components/TestApi.vue";

export default {
  props: ["service"],
  components: { AddApiDialog, UpdateApiDialog, ConfirmDelete, TestApi },
  data() {
    return {
      delete_id: null,
    };
  },
  methods: {
    getItems() {
      this.$store.dispatch("fetchEcommerceApis", {
        filter: {
          service_id: this.service.id,
        },
      });
      this.$store.dispatch("fetchEcommerceModels", {
        filter: {},
      });
    },
    addApi() {
      this.$refs.addApiDialog.open(this.service.id);
    },
    updateApi(item) {
      this.$refs.updateApiDialog.open(item);
    },
    deleteApi(item) {
      this.delete_id = item.id;
      this.$refs.confirmDelete.open();
    },
    removeApi() {
      HTTP.delete("v1/ecommerce/apis/" + this.delete_id)
        .then(() => {
          this.$successMessage = "Une API E-commerce a été supprimée";
          this.getItems();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    testApi(item) {
      this.$refs.testApi.open(item);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.ecommerceApisLoading;
    },
    apis() {
      return this.$store.getters.getEcommerceApis;
    },
  },
  mounted() {
    this.getItems();
  },
  watch: {
    service() {
      this.getItems();
    },
  },
};
</script>
